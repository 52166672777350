/* 04.6 Form CSS */

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="file"],
select {
  width: 100%;
  min-height: 56px;
  padding: 3px 20px;

  color: var(--clr-body);
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: none;
  background-color: #f5f5f5;

  @include placeholder {
    color: #9f9f9f;
  }

  &:focus {
    border-color: var(--clr-primary);
    background-color: var(--clr-white);
  }
}

textarea {
  width: 100%;
  min-height: 130px;
  padding: 10px 20px;

  color: var(--clr-body);
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  outline: none;
  background-color: #f5f5f5;

  @include placeholder {
    color: #9f9f9f;
  }

  &:focus {
    border-color: var(--clr-primary);
    background-color: var(--clr-white);
  }
}

.single-input {
  label {
    color: var(--clr-heading);
  }
  .description {
    font-style: italic;
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 0;
  }
}
