/* 05.02 Mobile Menu Toggle CSS */

.header-mobile-menu-toggle {
    display: flex;

    padding: 0 10px;

    & .toggle {
        display: flex;
        flex-direction: column;

        padding: 0;

        border: none;
        background-color: #111827;

        & i {
            position: relative;

            display: block;
            overflow: hidden;

            width: 24px;
            height: 2px;

            & + i {
                margin-top: 6px;
            }

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;

                flex: 1 0 100%;

                width: 100%;
                height: 100%;

                content: "";

                background-color: var(--clr-white);
            }

            &.icon-top,
            &.icon-bottom {
                &::before {
                    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
                    transform: scaleX(1) translateZ(0);
                    transform-origin: right;
                }

                &::after {
                    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                    transform: scaleX(0) translateZ(0);
                    transform-origin: left;
                }
            }

            &.icon-middle {
                &::before {
                    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
                    transform: scaleX(1) translateZ(0);
                    transform-origin: left;
                }

                &::after {
                    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                    transform: scaleX(0) translateZ(0);
                    transform-origin: right;
                }
            }
        }

        &:hover {
            & i {
                &::before,
                &::after {
                    background-color: var(--clr-white);
                }

                &.icon-top,
                &.icon-bottom {
                    &::before {
                        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                        transform: scaleX(0) translateZ(0);
                    }

                    &::after {
                        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
                        transform: scaleX(1) translateZ(0);
                    }
                }

                &.icon-middle {
                    &::before {
                        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                        transform: scaleX(0) translateZ(0);
                    }

                    &::after {
                        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
                        transform: scaleX(1) translateZ(0);
                    }
                }
            }
        }
    }

    @media #{$extra-small-mobile} {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.header-light {
    & .header-mobile-menu-toggle {
        & .toggle {
            & i {
                &::before,
                &::after {
                    background-color: var(--clr-white);
                }
            }

            &:hover {
                & i {
                    &::before,
                    &::after {
                        background-color: var(--clr-white);
                    }
                }
            }
        }
    }
}